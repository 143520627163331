<template>
  <footer class="up-footer">
    <p class="up-footer__text">
      (с) ООО «МАПО» ИНН 1840095426
      <a href="mailto:help@proffo.ru">help@proffo.ru</a>
    </p>
    <a
      v-if="documents.privacy.hasFile"
      class="up-footer__document"
      :href="documents.privacy.downloadLink"
      download
    >
      {{ documents.privacy.name }}
    </a>
    <a
      v-if="documents.user_agreement.hasFile"
      class="up-footer__document"
      :href="documents.user_agreement.downloadLink"
      download
    >
      {{ documents.user_agreement.name }}
    </a>
  </footer>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "TheFooter",
  computed: {
    ...mapGetters({ documents: "getDocuments" }),
  },
  methods: {
    ...mapActions(["fetchAccountData", "fetchDocuments"]),
  },
  created() {
    this.fetchDocuments();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/layout/the-footer.scss";
</style>