<template>
  <div class="up-login-wrapper" @keyup.enter="loginHandler">
    <div class="up-login-wrapper__container">
      <div class="up-login">
        <h1 class="up-login__title">Proffo<span>.ru</span></h1>
        <el-form>
          <el-form-item label="Телефон">
            <el-input
              v-model="phoneNumber"
              placeholder="Телефон"
              prefix-icon="el-icon-phone"
            />
          </el-form-item>
          <el-form-item label="Пароль">
            <el-input
              v-model="form.password"
              show-password
              placeholder="Пароль"
              prefix-icon="el-icon-lock"
            />
          </el-form-item>
          <el-button
            class="up-login__btn-change-pwd"
            type="text"
            @click="modalChangePwdVisible = true"
          >
            Забыли пароль?
          </el-button>
          <AppButton
            class="up-login__btn-login"
            v-loading="loading"
            @click="loginHandler"
          >
            вход
          </AppButton>
        </el-form>
        <TheFooter />
        <AppSimpleModal
          title="Смена пароля"
          :visible.sync="modalChangePwdVisible"
          action-text="Выслать новый пароль"
          @close="modalChangePwdVisible = false"
          @action="changePasswordHandler"
        >
          Введите Ваш логин (номер телефона)
          <template slot="body">
            <el-input
              v-model="modalPhoneNumberModel"
              placeholder="Введите номер телефона"
            />
          </template>
        </AppSimpleModal>
      </div>
      <div class="up-login-wrapper__bg-block up-bg-block">
        <img
          class="up-bg-block__img"
          src="@/assets/images/login-bg.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
import Str from "@/helpers/Str";

import showErrorMessage from "@/mixins/showErrorMessage.mixin";

import TheFooter from "@/components/layout/TheFooter";
import AppSimpleModal from "@/components/AppSimpleModal";
import AppButton from "@/components/ui/AppButton";

export default {
  name: "Login",
  mixins: [showErrorMessage],
  components: { TheFooter, AppSimpleModal, AppButton },
  data: () => ({
    form: {
      phone: "",
      password: "",
    },
    loading: false,
    modalChangePwdVisible: false,
    modalPhoneNumber: "",
  }),
  computed: {
    phoneNumber: {
      get() {
        return this.form.phone;
      },
      set(val) {
        this.form.phone = Str.phoneFormatted(val);
      },
    },
    modalPhoneNumberModel: {
      get() {
        return this.modalPhoneNumber;
      },
      set(val) {
        this.modalPhoneNumber = Str.phoneFormatted(val);
      },
    },
  },
  methods: {
    async loginHandler() {
      if (this.loading) return;

      this.loading = true;

      try {
        const { data } = await this.$axios.post("/auth/signin", this.form);

        this.$cookies.set("access", data.token, "30d");
        this.$cookies.set("user-role", data.roles[0], "30d");

        window.location.reload();
      } catch (e) {
        if (!e.response) {
          this.$message({
            message:
              "На сервере ведутся технические работы. Повторите попытку позже",
            type: "error",
            offset: 150,
          });
          return;
        }

        this.showErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async changePasswordHandler() {
      try {
        const { data: res } = await this.$axios.delete("/auth/lostPassword", {
          data: {
            phone: this.modalPhoneNumber,
          },
        });

        this.$message({
          message: res.message,
          type: "success",
          duration: 5000,
        });

        this.modalChangePwdVisible = false;
      } catch (e) {
        this.showErrorMessage(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/login.scss";
</style>