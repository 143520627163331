<template>
  <component
    :is="htmlTag"
    v-bind="$attrs"
    v-on="$listeners"
    class="up-button"
    :class="buttonThemeClass"
    type="button"
  >
    <span><slot /></span>
  </component>
</template>

<script>
export default {
  name: "AppButton",
  props: {
    theme: String,
  },
  computed: {
    htmlTag() {
      let tag = "button";

      if (this.$attrs.to) {
        tag = "router-link";
      } else if (this.$attrs.href) {
        tag = "a";
      }

      return tag;
    },
    buttonThemeClass() {
      return this.theme ? `up-button--${this.theme}` : "";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/ui/app-button.scss";
</style>